/*
Usage
    this.$local - 브라우저가 닫기더라도 값이 유지됨, 로그인화면에 아이디 저장 등에 사용
    this.$session - 세션이 유지되는동안만 유지됨, 로그인정보, 메뉴권한 등에 사용

Methods
  - save(key, value): key-value 저장
  - load(key, value): 값가져오기 (default 값 지정)
  - delete(key): 삭제

ex)
    this.$local.save('useerId', 'admin')
    this.$local.load('useerId') -> 'admin'

    this.$local.save('terms', [true, false, true])
    this.$local.load('terms') -> (3)[true, false, true]
*/

const local = {
    save: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value))
    },
    load: (key, value) => {
        return JSON.parse(localStorage.getItem(key)) || value
    },
    delete: (key) => {
        localStorage.removeItem(key)
    }
}

const session = {
    save: (key, value) => {
        sessionStorage.setItem(key, JSON.stringify(value))
    },
    load: (key, value) => {
        return JSON.parse(sessionStorage.getItem(key)) || value
    },
    delete: (key) => {
        sessionStorage.removeItem(key)
    }
}

const cookie = {
    setCookie: (name, val, exp) =>{
        let date = new Date();
        date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
        document.cookie = name + "=" + val + ";expires=" + date.toUTCString() + ";";
    },
    getCookie: function (name) {
        let value = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
        return value ? value[2] : null;
    }
};

export {
    local, session, cookie
}

