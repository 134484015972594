/*
Usage
    this.$utils - 공통함수

Methods
  - getAppHeight(): 화면 높이
  - getPath(): 현재 열려진 화면의 path
  - getUserInfo(): 사용자 세션정보

  <체크 관련함수>
  - isMobile(): Client 장비가 모바일기기인지 체크
  - isNull(val) / isNotNull(val): 널값 체크

  <데이터 깊은복사 관련함수>
  - clone(source): NULL 값을 가진 key는 삭제하여 복사 - ajax 데이터량 줄임
  - cloneDeep(source): 깊은복사

  <문자열 변환 관련함수>
  - lpad(str, padLen, padStr): 왼쪽 채우기
  - localeNumber(num): 콤마 스트링 반환
  - getForString(preStr, n, surStr):  v-for 사용시 index를 문자열과 합치기 위함
  - camelToKebab(string): 카멜(camelToKebab) -> 케밥(camel-to-kebab)
  - camelToSnake(string): 카멜(camelToSnake) -> 스네이크(camel_to_snake)
  - snakeToCamel(string): 스네이크(snake_to_camel) -> 카멜(snakeToCamel)
  - snakeToPascal(string): 스네이크(snake_to_pascal) -> 파스칼(SnakeToPascal)
  - pascalToSnake(string): 파스칼(PascalToSnake) -> 스네이크(pascalToSnake)
  - phoneNoAutoHyphen(e): 전화번호 자동하이픈 (onInput 이벤트)
  - businessNoAutoHyphen(e): 사업자번호 자동하이픈 (onInput 이벤트)
  - numberToKrw(num): 금액 등 숫자-> 한글숫자로 변환
  - trimPath(num): URL path 만 추출
  - range(formDate, toDate): 기간 문자열

  <날짜/시간 관련함수>
  - date(diff, unit): 오늘날짜 기준 차이 ex) this.$utils.date(-1, 'months') -> 한달전
  - time(diff, unit): 현재시간 기준 차이 ex) this.$utils.date(-1, 'hours') -> 한시간전

  <콤보박스 관련함수>
  - loadComboMap - deprecated
  - initComboMap(map, callback): 콤보맵(map)에 파라미터를 통해 서버에서 리스트를 받아 담은후 콜백함수(callback) 호출
  - <내부> getCombo(combo, params, callback, data): 콤보맵(combo)에 파라미터를 통해 서버에서 리스트를 받아 담은후 콜백함수(callback) 호출
  - comboMapFilter(combo, filter): 필터를 설정하여 콤보박스에 특정 리스트만 노출
  - resetCombo(combo, filter, component): 필터를 설정하여 콤보박스에 특정 리스트만 노출, 해당 콤포넌트 자동갱신
  - getComboMapName(combo, code): 콤보맵 명칭컬럼 값추출
  - getComboMapExpand1(combo, code): 콤보맵 확장컬럼1 값추출
  - getComboMapExpand2(combo, code): 콤보맵 확장컬럼2 값추출

  <로그인/사이드메뉴 관련함수>
  - login(ownerCd, userCd, userPwd, success): 로그인
  - <내부> setSideMenu(result, userNav, userRole): 사용자 사이드메뉴구축
  - <내부> makeSidebarNavItem(children, sidebarNav): 사이드메뉴아이템 생성
  - logout(): 로그아웃 및 세션 정보 삭제

  <UI 처리 관련함수>
  - draggable(el, hd): 해당팝업 헤더에 드래그 이벤트 적용 (el: 대상 팝업, hd: 팝업 해더)



*/

import * as _ from 'lodash';
import {spinner as $spinner} from "@/components/PSpinner";
import {session as $session} from "@/utils/PStorageUtil";
import {ajax as $ajax} from "@/utils/PHttpUtil";
import {cipher as $cipher} from "@/utils/PCipherUtil";
import {createApp} from "vue";
import PModalPopup from "@/components/modal/PModalPopup.vue";

window.moment = require('moment-timezone')

const utils = {
    getAppHeight: () => {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    },
    getPath: () => {
        return utils.trimPath( (window.location.href.split('/#'))[1] )
    },
    getUserInfo() {
        return $session.load('userInfo', {})
    },

    /* 체크 관련함수 */
    isMobile: () => {
        // let UserAgent = navigator.userAgent;
        // if (UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null
        //     || UserAgent.match(/LG|SAMSUNG|Samsung/) != null) {
        //     return true;
        // }
        // else {
        //     return false;
        // }
        return utils.isTablet();
    },
    isTablet: () => {
        let userAgent = navigator.userAgent.toLowerCase();
        let isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        return isTablet
    },
    isNull: (val) => {
        return val === null || (typeof val === 'string' && val.trim() === '') || val === undefined;
    },
    isNotNull: (val) => {
        return !utils.isNull(val);
    },

    /* 데이터 깊은복사 관련함수 */
    clone: (source) => {
        let destination = null
        if(utils.isNotNull(source)) {
            if(typeof source === 'object') {
                if(Array.isArray(source)) {
                    destination = []
                }
                else {
                    destination = {}
                }
                for(let key in source) {
                    let result = utils.clone(source[key])
                    if(result || result === 0 || result === false) {
                        destination[key] = result
                    }
                }
            }
            else {
                return source
            }
        }
        return destination
    },
    cloneDeep: (source) => {
        return _.cloneDeep(source)
    },

    /* 문자열 변환 관련함수 */
    lpad: (str, padLen, padStr) => {
        str += ""; // 문자로
        padStr += ""; // 문자로
        while (str.length < padLen)
            str = padStr + str;
        str = str.length >= padLen ? str.substring(0, padLen) : str;
        return str;
    },
    localeNumber: (num) => {
        let parts = num?.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    },
    getForString: (preStr, n, surStr) => {
        return ( preStr || '') + n + (surStr || '')
    },
    camelToKebab: (string) => {
        if(string) {
            return string.replace( /([A-Z])/g, "-$1" ).toLowerCase()
        }
        return string
    },
    camelToSnake: (string) => {
        if(string) {
            return string.replace( /([A-Z])/g, "_$1" ).toLowerCase()
        }
        return string
    },
    snakeToCamel: (string) => {
        if(string) {
            return string.toLowerCase().replace( /([-_]\w)/g, g => g[1].toUpperCase() )
        }
        return string
    },
    snakeToPascal: (string) => {
        if(string) {
            let camelCase = utils.snakeToCamel(string);
            let pascalCase = camelCase[0].toUpperCase() + camelCase.substr(1);
            return pascalCase;
        }
        return string
    },
    pascalToSnake: (string) => {
        if(string) {
            let camelCase = string[0].toLowerCase() + string.substr(1);
            let snakeCase = utils.camelToSnake(camelCase);
            return snakeCase;
        }
        return string
    },
    phoneNoAutoHyphen(e) {
        let value = e.getValue()
            .replace(/[^0-9]/g, "").substr(0, 11).replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,"$1-$2-$3").replace("--", "")
        e.setValue(value)
    },
    businessNoAutoHyphen(e) {
        let value = e.getValue().replace(/[^0-9]/g, "").substr(0, 10)
        if(value.length > 5) {
            value = value.substr(0, 3) + '-' +  value.substr(3, 2) + '-' +  value.substr(5)
        }
        else if(value.length > 3) {
            value = value.substr(0, 3) + '-' +  value.substr(3)
        }
        e.setValue(value)
    },
    numberToKrw: (number) => {
        const hanA = ["","일","이","삼","사","오","육","칠","팔","구","십"]
        const danA = ["","십","백","천","","십","백","천","","십","백","천","","십","백","천"]
        let result = ""
        if(parseInt(number)) {
            const num = parseInt(number) + ""
            let str = ""
            for(let i = num.length - 1; i >= 0; i--) {
                let han = hanA[num.charAt(num.length - (i + 1))]
                if(han !== "") {
                    str += han + danA[i]
                }
                if(i === 4 && str.length > 0) {
                    result += str + "만 "
                    str = ""
                }
                if(i === 8 && str.length > 0) {
                    result += str + "억 "
                    str = ""
                }
                if(i === 12 && str.length > 0) {
                    result += str + "조 "
                    str = ""
                }
            }
            result += str + "원"
        }
        return result
    },
    trimPath: (path) => {
        let _path = path
        _path = _path.split("?")[0];
        if(_path.startsWith('/')) {
            _path = _path.substring(1)
        }
        return _path
    },
    range: (formDate, toDate) => {
        let ret = [formDate, toDate].join(' ~ ')
        return ret
    },

    /* 날짜/시간 관련함수 */
    date: (diff, unit) => {
        /* unit : years, months, weeks, days (hours, minutes, seconds) */
        if(diff) {
            if(!unit || unit == 'days') {
                return window.moment().tz('Asia/Seoul').add(diff, 'days').format('YYYY-MM-DD')
            }
            else {
                return window.moment().tz('Asia/Seoul').add(diff, unit).format('YYYY-MM-DD')
            }
        }
        else {
            return window.moment().tz('Asia/Seoul').format('YYYY-MM-DD')
        }
    },
    time: (diff, unit) => {
        /* unit : hours, minutes, seconds */
        if(diff) {
            if(!unit || unit == 'hours') {
                return window.moment().add(diff, 'hours').format('HH:mm')
            }
            else {
                return window.moment().add(diff, unit).format('HH:mm')
            }
        }
        else {
            return window.moment().format('HH:mm')
        }
    },

    /* 콤보 관련함수 */
    // loadComboMap is deprecated -> initComboMap
    loadComboMap: (target, eventName, map, callback) => {
        utils.initComboMap(map, callback)
    },
    initComboMap: (map, callback) => {
        if(!map || Object.keys(map).length === 0) {
            if(callback instanceof Function) {
                callback()
            }
        }
        else {
            $spinner(true, true)
            for(let key in map) {
                let combo = map[key]
                utils.getCombo(combo, null,()=>{
                    for(let _key in map) {
                        if(map[_key].data === undefined) {
                            return
                        }
                    }
                    if(callback instanceof Function) {
                        $spinner(false, true)
                        callback()
                    }
                }) //, combo.data)
            }
        }
    },


    getCombo: (combo, params, callback, data) => {
        let submit = (result) => {
            if(combo) {
                combo._keys = []
                combo._value = {}
                combo._first = null
                combo._init = null
                combo.data = {}
                combo._expand1 = {}
                combo._expand2 = {}
                combo._useYn = {}
                combo.data._ordered_keys = []
                combo.data._notused_keys = []
                for(let set of result) {
                    combo.data[set.code] = set.name
                    combo._useYn[set.code] = set.useYn
                    combo._keys.push(set.code)
                    if(set.useYn === 'Y') {
                        if(set.value) {
                            if(typeof set.value === 'string'){
                                combo._value[set.code] = set.value.split(',')
                            }else{
                                combo._value[set.code] = []
                                // change value type to string
                                combo._value[set.code].push(set.value+'')
                            }
                        }
                        if(set.expand1){
                            combo._expand1[set.code] = set.expand1
                        }

                        if(set.expand2){
                            combo._expand2[set.code] = set.expand2
                        }

                        combo.data._ordered_keys.push(set.code)
                        if(!combo._first) {
                            combo._first = set.code
                        }
                    }
                    else {
                        combo.data._notused_keys.push(set.code)
                    }
                }
                if(combo.data._ordered_keys.length === 1) {
                    combo._init = combo._first
                }
            }
            else {
                 /* eslint-disable no-console */
                console.error('Can`t find combomap!')
            }

            if(callback instanceof Function) {
                callback()
            }
        }

        if(data) {
            submit(data)
        }
        else {
            $ajax({
                public: combo.public || false,
                url: combo.url,
                params: params || combo.params,
                callback: (result)=> {
                    submit(result)
                }
            })
        }
    },

    createComboMap: (map, callback) => {
        if(!map || Object.keys(map).length === 0) {
            if(callback instanceof Function) {
                callback()
            }
        }
        else {
            $spinner(true, true)
            for(let key in map) {
                let combo = map[key]
                utils.getCreateCombo(combo, null,()=>{
                    for(let _key in map) {
                        if(map[_key].data === undefined) {
                            return
                        }
                    }
                    if(callback instanceof Function) {
                        $spinner(false, true)
                        callback()
                    }
                }) //, combo.data)
            }
        }
    },


    getCreateCombo: (combo, params, callback, data) => {
        let submit = (result) => {
            if(combo) {
                combo._first = null
                combo._init = null
                combo.data = []
                combo.data.push({value: '',label:'전체', bgColor:''});

                for(let set of result) {
                    if(set.useYn !== 'Y') continue;
                    let data = {};
                    data["value"] = set.code;
                    data["label"] = set.name;
                    data["color"] = `#${set.expand1}`;
                    combo.data.push(data);
                }
            }
            else {
                 /* eslint-disable no-console */
                console.error('Can`t find combomap!')
            }

            if(callback instanceof Function) {
                callback()
            }
        }

        if(data) {
            submit(data)
        }
        else {
            $ajax({
                url: combo.url,
                params: params || combo.params,
                callback: (result)=> {
                    submit(result)
                }
            })
        }
    },


    comboMapFilter: (combo, filter) => {
        utils.resetCombo(combo, filter, null)
    },
    resetCombo: (combo, filter, component) => {
        if(combo.data) {
            combo._first = null
            combo.data._ordered_keys = []
            combo.data._notused_keys = []
            // change value type to string
            const value = filter + ''
            let code = null;
            if(component) {
                code = component.getValue()
            }
            for(let key of combo._keys) {
                if(combo._useYn[key] === 'Y' && combo._value[key] && combo._value[key].includes(value)) {
                    combo.data._ordered_keys.push(key)
                    if(!combo._first) {
                        combo._first = key
                    }
                }
                else {
                    combo.data._notused_keys.push(key)
                    if(key == code) {
                        code = ''

                    }
                }
            }
            if(component) {
                component.setValue(code)
                component.reload()
            }
        }
    },
    getComboMapName: (combo, code) => {
        return combo.data[code]
    },
    getComboMapExpand1: (combo, code) => {
        return combo._expand1[code]
    },
    getComboMapExpand2: (combo, code) => {
        return combo._expand2[code]
    },

    /* 로그인/사이드메뉴 관련함수 */
    login: (userCd, userPwd, success, fail)=> {
        $ajax({
            public: true,
            url: '/System/rsa',
            params: {},
            callback: (result) => {
                userCd = $cipher.encrypt(result.module, result.exponent, userCd)
                userPwd = $cipher.encrypt(result.module, result.exponent, userPwd)
                $ajax({
                    public: true,
                    url: '/Bdr/Login/submit',
                    params: {
                        userCd,
                        userPwd,
                    },
                    callback: (result) => {
                        $session.save('userInfo', result.userInfo)
                        if(result.partnerInfo && result.partnerInfo.partnerCd !== null){
                            $session.save('partnerInfo', result.partnerInfo)
                        }
                        if(success && success instanceof Function) {
                            success()
                        }
                    },
                    error: () => {
                        if(fail && fail instanceof Function) {
                            fail()
                        }
                    }
                });
            }
        });
    },
    setSideMenu: (result, userNav, userRole) => {
        try {
            let menuRole = result.menuRole
            let level = []
            level[0] = {}
            for(let menu of menuRole) {
                if(menu.programPath && !userRole[menu.programPath]) {
                    userRole[menu.programPath] = {
                        menuNm: menu.menuNm,
                        saveAbleYn: menu.saveAbleYn,
                        delAbleYn: menu.delAbleYn,
                        execAbleYn: menu.execAbleYn
                    }
                }
                if(!level[menu.menuLvl]) {
                    level[menu.menuLvl] = {}
                }
                level[menu.menuLvl][menu.menuId] = menu
                if(menu.menuLvl > 1) {
                    if(!level[menu.menuLvl-1][menu.upperMenuId].child) {
                        level[menu.menuLvl-1][menu.upperMenuId].child = []
                    }
                    level[menu.menuLvl-1][menu.upperMenuId].child.push(menu)
                }
                else {
                    if(!level[0].root) {
                        level[0].root = []
                    }
                    level[0].root.push(menu)
                }
            }

            utils.makeSidebarNavItem(level[0].root, userNav)
        } catch (e) {
            // try - catch (e) { ... } 안에서는 this 가 안먹힘
            modal({
                color: 'danger',
                title: '오류',
                content: '회원계정 메뉴권한에 문제점이 발견되었습니다. 관리자에게 문의하십시오.',
            })
        }
    },
    makeSidebarNavItem: (children, sidebarNav) => {
        for(let child of children) {
            let menu = {}
            sidebarNav._children.push(menu)
            menu.name = child.menuNm
            if(child.menuIcon) {
                menu.icon = utils.camelToKebab(child.menuIcon)
            }
            if(child.programPath) {
                menu.to = child.programPath
            }
            if(child.child) {
                menu._name = 'PSidebarNavDropdown'
                menu._children = []
                utils.makeSidebarNavItem(child.child, menu)
            }
            else {
                menu._name = 'PSidebarNavItem'
            }
        }
    },
    logout: (logoutCallback)=> {
        $ajax({
            public: true,
            url: '/System/logout',
            params: {},
            callback: () => {
                if(logoutCallback instanceof Function){
                    logoutCallback()
                }
            }
        })
        $session.delete('userInfo')
        $session.delete('userNav')
        $session.delete('userRole')
        $session.delete('breadcrumb')
        $session.delete('partnerInfo')
        $session.save('tabList', [])

        // router push 이용시 헤더정보가 감지가 되지 않는 문제가 있어 강제 새로고침을 위한 location.href 이용
        location.href = '/login'
    },

    /* UI 처리 관련함수 */
    draggable: (el, hd) => {
        let px = 0, py = 0, mx = 0, my = 0;
        if (hd) {
            // if present, the header is where you move the DIV from:
            hd.onmousedown = dragMouseDown;
        } else {
            // otherwise, move the DIV from anywhere inside the DIV:
            el.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            mx = e.clientX;
            my = e.clientY;
            let x = window.getComputedStyle(el,null).getPropertyValue('left')
            let y = window.getComputedStyle(el,null).getPropertyValue('top')
            x = x.substr(0, x.length - 2)
            y = y.substr(0, y.length - 2)
            px = parseInt(x);
            py = parseInt(y);

            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            let dx = e.clientX - mx;
            let dy = e.clientY - my;
            // set the element's new position:
            el.style.left = (px + dx) + "px";
            el.style.top = (py + dy) + "px";
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    },

    multiJsonParse(mysqlJsonObject) {
      return mysqlJsonObject
        .replaceAll("},{", "}___{")
        .split("___")
        .map((data) => JSON.parse(data));
    },
    formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    },

    getComboLabel(field, data){

        let str = '';
        for (const item of data) {
            if (item.value === field) {
                str = item.label;
                break;
            }
        }
        return str;
    },

    getComboColor(field, data){

        let str = '';
        for (const item of data) {
            if (item.value === field) {
                str = item.color;
                break;
            }
        }
        return str;
    },

    fileDownloadFromBinary(binaryData, fileName){
        // 1. binaryString (예시로 base64 또는 어떤 바이너리 데이터라 가정)

        // 2. 이진 데이터를 Blob으로 변환
        const byteCharacters = atob(binaryData);  // base64 문자열을 디코딩 (필요에 따라)
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });

        // 3. Blob을 사용해 파일 생성
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        // 4. 리소스 해제
        URL.revokeObjectURL(url);
    }

}

let modalApp = null;
const modal = (options) => {
    const __PModalPopup_default_color = '#888'
    const __PModalPopup_default_bg_color = '#FFFFFF'
    const __PModalPopup_default_border_color = '#888'
    // const __PModalPopup_default_title = '알림'
    // const __PModalPopup_default_content = '처리완료'
    const __PModalPopup_default_button_name = '확인'
    const __PModalPopup_default_button_variant = 'outline'


    let el = document.querySelector('.p-modal')
    if(el) {
        return
    }

    let _options = utils.clone(options) || {}
    if(options.buttons) {
        let _buttons = []
        for(const button of options.buttons) {
            // 이상하게 concat 으로 연결하면 바인딩처리가 안되어 push 로 처리
            _buttons.push({
                name: button.name || __PModalPopup_default_button_name,
                color: button.color || options.color || __PModalPopup_default_color,
                variant: button.variant === '' ? '' : (button.variant || __PModalPopup_default_button_variant),
                borderColor:button.borderColor || options.borderColor || __PModalPopup_default_border_color,
                bgColor:button.bgColor || options.bgColor || __PModalPopup_default_bg_color,
                callback: button.callback
            })
        }
        _options['buttons'] = _buttons
    }
    else {
        _options['buttons'] = [{
            name: __PModalPopup_default_button_name,
            color: options.color || __PModalPopup_default_color,
            variant: __PModalPopup_default_button_variant,
            borderColor:options.borderColor || __PModalPopup_default_border_color,
            bgColor:options.bgColor || __PModalPopup_default_bg_color,
            callback: null
        }]
    }

    const modalContainer = document.createElement('div');
    modalContainer.classList.add('p-modal-container');
    modalContainer.style.position = 'fixed';
    modalContainer.style.top = 0;
    modalContainer.style.left = 0;
    modalContainer.style.right = 0;
    modalContainer.style.bottom = 0;
    modalContainer.style.backgroundColor = 'rgba(252, 252, 252, 0.5)'; /* 배경 투명도 조절 */
    modalContainer.style.zIndex = 9999; /* 다른 요소들보다 위에 표시 */
    modalContainer.style.transition = 'opacity 0.3s ease'; /* 부드러운 페이드 인/아웃 효과 */

    const app = document.querySelector('#app');
    modalApp = createApp(PModalPopup, _options);
    modalApp.mount(modalContainer);
    app.appendChild(modalContainer);
}

const unmountModal = () => {
    document.querySelector('.p-modal-container').remove();
    modalApp.unmount();
    modalApp = undefined;
}

export { utils, modal, unmountModal }
