<template>
  <div class="p-modal-container">
    <div class="p-modal-body">
      <i><img src="@/assets/img/common/complete_simbol.png" alt=""></i>
      <div class="modalMes">{{content}}</div>
      <div class="modalBtn">
        <button v-for="button in buttons" :key="button.name" class="p-modal-button"
                @click="() => closeAndCall(button.callback)"
        >
            {{ button.name }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>


const __PModalPopup_default_color = '#888'
const __PModalPopup_default_bg_color = '#FFFFFF'
const __PModalPopup_default_border_color = '#888'
const __PModalPopup_default_title = '알림'
const __PModalPopup_default_content = '처리완료'
const __PModalPopup_default_button_name = 'CLOSE'
const __PModalPopup_default_button_variant = 'outline'

import {unmountModal as $unmount} from "@/utils/PWebUtil";

export default {
  name: 'PModalPopup',
  props: {
    content: {
      type: String,
      default: __PModalPopup_default_content,
    },
    color: {
      type: String,
      default: __PModalPopup_default_color,
    },
    size: {
      type: String,
      default: 'sm'
    },
    title: {
      type: String,
      default: __PModalPopup_default_title,
    },
    buttons: {
      type: Array,
      default: () => [{
        name: __PModalPopup_default_button_name,
        color: __PModalPopup_default_color,
        variant: __PModalPopup_default_button_variant,
        borderColor: __PModalPopup_default_border_color,
        bgColor: __PModalPopup_default_bg_color,
        callback: null
      }],
    },
    callback: Function,
  },
  methods: {
    closeAndCall(callback) {
      if (callback) {
        callback()
      }

      this.close()
    },
    close() {
      if (this.callback) {
        this.callback()
      }

      $unmount();
    },
  },
  mounted() {
  }
};

</script>
<style scoped>

.p-modal-container {
  background-color: #fdfdfd;
  margin: 15% auto; /* 모달을 화면 중앙에 위치 */
  border: 1px solid #888;
  width: 80%; /* 모달의 너비 조절 */
  max-width: 500px; /* 모달의 최대 너비 설정 */
  position: relative; /* 내부 요소들이 절대 위치를 사용할 때 필요 */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
}

.p-modal-body {
  
  display:flex;
  flex-direction: column;
  text-align: center;
  align-items:center;
  justify-content:center;
  padding:5rem 3rem;
  box-sizing:border-box;

}

.p-modal-body i { 
  display:inline-block;
  width:70px;
  height:70px;
  background:var(--main_red);
  border-radius:50%;
 }

.p-modal-body .modalMes { 
  font-size:2.2rem;
  color:var(--c3a);
  margin:3rem 0 5rem;
 }
.p-modal-body .modalBtn {
  display:flex;
  align-items: center;
  justify-content:center;
  gap:5px;
}
.p-modal-body button { 
  font-size:1.6rem;
  color:var(--white);
  font-weight:500px;
  border:0;
  background:var(--main_red);
  width: 130px;
  height:50px;
  border-radius:500px;

 }


</style>
