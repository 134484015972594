<template>
    <nav class="all">
        <div class="inner">
            <div class="flex_box">
                <div class="m_view">
                    <div class="m_company" v-if="userInfo && userInfo.userNm"><i class="fas fa-building"></i><strong>{{ userInfo.userNm }}</strong>님</div>
                    <div class="ins"><a href=""><img src="@/assets/img/common/ins_icon.png"></a></div>
                </div>
                
                <button class="close">
                    <svg version="1.1" id="&#xB808;&#xC774;&#xC5B4;_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="35px" height="35px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                        <g>
                            <line style="fill:none;stroke:#999;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="3" y1="3" x2="69" y2="69"></line>
                            <line style="fill:none;stroke:#999;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" x1="69" y1="3" x2="3" y2="69"></line>
                        </g>
                    </svg>
                </button>
            </div>
            <Gnb />
        </div>
    </nav>
</template>

<script>
    import $ from 'jquery';
    import Gnb from './Gnb.vue';

    export default {
        name: 'allGnb',
        components: {
            'Gnb':Gnb,
        },
        data() {
            return {
                userInfo: null,
            };
        },
        mounted(){

            this.userInfo = this.$session.load('userInfo');

            $(document).on('click', '.header .toggle', function(e){
                e.preventDefault();

                $(this).addClass('show');
                $('.all').addClass('show');
                $('.all .close').addClass('show');
                $('body').addClass('show');
                $('html').addClass('show');
            });

            $(document).on('click', '.all .close', function(e){
                e.preventDefault();

                $(this).removeClass('show');
                $('.all').removeClass('show');
                $('.header .toggle').removeClass('show');
                $('body').removeClass('show');
                $('html').removeClass('show');                
            });


            $(document).on('click', '.all .de1_a', function(e){
                e.preventDefault();

                if ($(this).hasClass('active') == false) {
                    $('.de1_li').removeClass('active');
                    $('.de1_a').removeClass('active');
                    $('.de2_ul').stop().slideUp(300);

                    $(this).addClass('active');
                    $(this).parent('.de1_li').addClass('active');
                    $(this).parent('.de1_li').children('.de2_ul').stop().slideDown(300);
                } else {
                    $(this).removeClass('active');
                    $(this).parent('.de1_li').removeClass('active');
                    $(this).parent('.de1_li').children('.de2_ul').stop().slideUp(300);
                }                
            });

            $(".all .de1_a").attr("href", "#;");
        },
        method: {
        },
        watch: {
            $route(to, form) {
                // next(false);    
                    
                // return false;
                // if (to.path !== form.path) this.data(this.$route.params.index);
            },
        }
    }
</script>


<style>

    @import '@/assets/css/common.css';

</style>