import {createRouter, createWebHistory} from "vue-router";

import blankLayout from "@/layout/blankLayout.vue";
import defaultLayout from "@/layout/defaultLayout.vue";
import companyLayout from "@/layout/companyLayout.vue";
import {session as $session} from "@/utils/PStorageUtil";


const checkAccess = (isCompanyRoute) => (to, from, next) => {
    const userInfo = $session.load('userInfo');

    // 비회원일 시 로그인 창으로
    if (userInfo === undefined) {
        console.log(to.fullPath)
        $session.save("loginRedirectUrl", to.fullPath); // url저장 알림톡을 위한
        next({ path: '/login' }); // 로그인 페이지로
        return;
    }

    // 회사 사용자와 일반 사용자에 대한 접근 제어
    const empNo = userInfo.empNo || '';

    const redirectUrl = $session.load("loginRedirectUrl");

    console.log(redirectUrl)
    if (isCompanyRoute) {
        // 기업 사용자만 접근 가능
        if (empNo.startsWith('K')) {
            next(); // 접근 허용
        } else {
            next({ path: '/' }); // 고객 페이지 메인으로
        }
    } else {
        // 일반 사용자만 접근 가능
        if (!empNo.startsWith('K')) {
            next(); // 접근 허용
        } else {
            next({ path: '/company' }); // 기업 페이지 메인으로
        }
    }
};

const goPageAfterLogin = () => {
    const redirectUrl = $session.load("loginRedirectUrl");

    if (redirectUrl) {
        $session.delete("loginRedirectUrl");
        location.href = redirectUrl
    } else {
        const {empNo} = $session.load('userInfo');
        if(empNo.startsWith('K')){
            location.href = '/company'
        }else{
            location.href = '/'
        }
    }
};

const goPageAfterSocialLink = () => {
    const redirectUrl = $session.load("loginRedirectUrl");

    if (redirectUrl) {
        $session.delete("loginRedirectUrl");
        location.href = redirectUrl
    } else {
        const {empNo} = $session.load('userInfo');
        if(empNo.startsWith('K')){
            location.href = ('/company/mypage/member');
        }else{
            location.href = '/user/mypage/member'
        }
    }
};

const routes = [
    {
        path: "/", // [경로]
        name: "main", // [이름]
        component: () => import('@/page/user/Main'), // [로드 파일]
        meta: {layout: defaultLayout}
    },
    // 로그인 페이지
    {
        path: "/login",
        name: "login",
        component: () => import('@/page/Login'),
        meta: { layout: blankLayout },
    },
    // 소셜 로그인
    {
        path: "/login/naver",
        name: "NaverLoginCallback",
        component: () => import("@/page/login/NaverLoginCallback.vue"),
        meta: { layout: blankLayout },
        props: { goPageAfterLogin }
    },
    {
        path: "/login/kakao",
        name: "KakaoLoginCallback",
        component: () => import("@/page/login/KakaoLoginCallback.vue"),
        meta: { layout: blankLayout },
        props: { goPageAfterLogin }
    },
    {
        path: "/login/google",
        name: "GoogleLoginCallback",
        component: () => import("@/page/login/GoogleLoginCallback.vue"),
        meta: { layout: blankLayout },
        props: { goPageAfterLogin }
    },
    // 소셜 연동
    {
        path: "/mypage/member/link/naver",
        name: "NaverSocialLinkCallback",
        component: () => import("@/page/user/mypage/NaverAccountLinkCallback.vue"),
        meta: { layout: blankLayout },
        props: { goPageAfterSocialLink },
    },
    {
        path: "/mypage/member/link/kakao",
        name: "KaKaoSocialLinkCallback",
        component: () => import("@/page/user/mypage/KaKaoAccountLinkCallback.vue"),
        meta: { layout: blankLayout },
        props: { goPageAfterSocialLink },
    },
    {
        path: "/mypage/member/link/google",
        name: "GoogleSocialLinkCallback",
        component: () => import("@/page/user/mypage/GoogleAccountLinkCallback.vue"),
        meta: { layout: blankLayout },
        props: { goPageAfterSocialLink },
    },
    // 회원가입
    {
        path: "/join",
        name: "join",
        component: () => import('@/page/Join.vue'),
        redirect: '/join/agree',
        meta: {layout: defaultLayout},
        children: [
            {
                path: "agree",
                name: "agree",
                component: () => import("@/page/user/join/agree"),
            },
            {
                path: "certify",
                name: "certify",
                component: () => import("@/page/user/join/certify"),
            },
            {
                path: "input",
                name: "infoInput",
                component: () => import("@/page/user/join/input"),
            },
            {
                path: "complete",
                name: "complete",
                component: () => import("@/page/user/join/complete"),
            },
            {
                path: "policy",
                name: "policy",
                children: [
                    {
                        path: "serviceAgreement",
                        name: "serviceAgreement",
                        component: () => import("@/page/user/join/policy/service"),
                    },
                    {
                        path: "privacy",
                        name: "privacy",
                        component: () => import("@/page/user/join/policy/privacy"),
                    },
                ]
            },
        ],
    },

    // 기업 메뉴 시작
    {
        path: "/company",
        name: "company",
        component: () => import('@/page/user/Company'),
        beforeEnter: checkAccess(true),
        meta: {layout: companyLayout},
        children: [
            {
                path: "/company",
                name: "dashboard",
                component: () => import('@/page/company/Dashboard'),
            },


            // 기업 - 입고
            {
                path: "asked",
                name: "company_asked",
                component: () => import('@/page/company/Stock'),
                children: [
                    {
                        path: "ask_main",
                        name: "company_ask_main",
                        component: () => import("@/page/company/asked/CompanyAddPickupInfo"),
                        children: [
                            {
                                path: "item_reg",
                                name: "item_reg",
                                component: () => import("@/page/company/asked/ItemRegistration"),
                                /*
                                    children: [
                                    {
                                        path: "redDetail",
                                        name: "redDetail",
                                        component: () => import("@/page/company/asked/popup/RegDetail"),
                                        meta: {layout: blankLayout},
                                    },
                                ]*/
                            },
                            {
                                path: "barcode",
                                name: "barcode",
                                component: () => import("@/page/company/asked/Barcode"),
                            },
                            {
                                path: "stockway",
                                name: "stockway",
                                component: () => import("@/page/company/asked/StockWay"),
                            },
                        ]
                    },
                    {
                        path: "pickup_list/:page",
                        name: "company_pickup_list",
                        component: () => import("@/page/company/asked/CompanyPickupList"),
                    },
                    {
                        path: "return_list/:page",
                        name: "company_return_list",
                        component: () => import("@/page/company/asked/CompanyReturnList"),
                    },
                ],
            },

            // 기업 - 물품현황
            {
                path: "stocked",
                name: "company_stocked",
                children: [
                    {
                        path: "stock_list/:page",
                        name: "company_stock_list",
                        component: () => import("@/page/company/stocked/CompanyStockList"),
                    },
                    {
                        path: "release_list/:page",
                        name: "company_release_list",
                        component: () => import("@/page/company/stocked/release/CompanyReleaseList"),
                    },
                    {
                        path: "release_return_list/:page",
                        name: "company_release_return_list",
                        component: () => import("@/page/company/stocked/release/CompanyReleaseReturnList"),
                    },
                ],
            },

            // 기업 - 결제
            {
                path: "pay",
                name: "company_pay",
                children: [
                    {
                        path: "pay_list/:page",
                        name: "company_pay_list",
                        component: () => import("@/page/company/pay/CompanyPayList"),
                    },
                    {
                        path: "deposit_list/:page",
                        name: "company_deposit_list",
                        component: () => import("@/page/company/pay/CompanyDepositList"),
                    },
                    {
                        path: "deposit_charge",
                        name: "company_deposit_charge",
                        component: () => import("@/page/company/pay/CompanyDepositList/CompanyDepositCharge"),
                    },
                    {
                        path: "pay_after/:page",
                        name: "company_pay_after",
                        component: () => import("@/page/company/pay/CompanyPayLaterList"),
                    },
                    {
                        path: "settlement_statement/:page",
                        name: "company_settlement_statement",
                        component: () => import("@/page/company/pay/CompanySettlementStatement"),
                    },
                    {
                        path: "notice",
                        name: "company_pay_notice",
                        component: () => import("@/page/company/pay/CompanyPayNotice"),
                    },
                ],
            },

            // 기업 - 마이페이지
            {
                path: "mypage",
                name: "company_mypage",
                component: () => import('@/page/user/mypage'),
                children: [
                    /*{
                        path: "member",
                        name: "company_member",
                        component: () => import("@/page/user/mypage/member"),
                    },*/
                    {
                        path: "address",
                        name: "company_address",
                        component: () => import("@/page/user/mypage/address"),
                        children: [
                            {
                                path: "addrList_in",
                                name: "company_address_in",
                                component: () => import("@/page/user/mypage/addrList_in"),
                            },
                            {
                                path: "addrList_fo",
                                name: "company_address_fo",
                                component: () => import("@/page/user/mypage/addrList_fo"),
                            },
                        ]
                    },
                ],
            },


            // 기업-고객센터
            {
                path: "cs",
                name: "company_cs",
                component: () => import('@/page/user/cs'),
                children: [
                    {
                        path: "notice",
                        name: "company_notice",
                        component: () => import("@/page/user/cs/notice"),
                        children: [
                            {
                                path: ":currentPage",
                                name: "company_nlist",
                                component: () => import("@/page/user/cs/notice/nlist.vue"),
                            },
                            {
                                path: ":boardId/:isNotice",
                                name: "company_nview",
                                component: () => import("@/page/user/cs/notice/view"),
                            },
                            {
                                path: "nwrite",
                                name: "company_nwrite",
                                component: () => import("@/page/user/cs/notice/write"),
                            },
                        ]
                    },
                    {
                        path: "event",
                        name: "company_event",
                        component: () => import("@/page/user/cs/event"),
                        children: [
                            {
                                path: ":currentPage",
                                name: "company_elist",
                                component: () => import("@/page/user/cs/event/list"),
                            },
                            {
                                path: ":boardId",
                                name: "company_eview",
                                component: () => import("@/page/user/cs/event/view"),
                            },
                            {
                                path: "ewrite",
                                name: "company_ewrite",
                                component: () => import("@/page/user/cs/event/write"),
                            },
                        ]
                    },
                    {
                        path: "faq",
                        name: "company_faq",
                        component: () => import("@/page/user/cs/faq"),
                    },
                    {
                        path: "through",
                        name: "company_through",
                        component: () => import("@/page/user/cs/through"),
                    },
                    {
                        path: "unconfirmed",
                        name: "company_unconfirmed",
                        component: () => import("@/page/user/cs/Unconfirmed"),
                    },
                    {
                        path: "fba",
                        name: "company_fba",
                        component: () => import("@/page/user/cs/fba"),
                    },
                    {
                        path: "oto",
                        name: "company_oto",
                        component: () => import("@/page/user/cs/oto"),
                        children: [
                            {
                                path: ":currentPage",
                                name: "company_otoList",
                                component: () => import("@/page/user/cs/oto/otoList.vue"),

                            },
                            {
                                path: "otoWrite",
                                name: "company_otoWrite",
                                component: () => import("@/page/user/cs/oto/otoWrite"),
                                props: {
                                    goPageAfterLogin
                                },

                            },
                            {
                                path: 'otoDetail/:boardId',
                                name: 'company_otoDetail',
                                component: () => import('@/page/user/cs/oto/otoDetail'),

                            },
                            {
                                path: 'otoModify/:boardId',
                                name: 'company_otoModify',
                                component: () => import('@/page/user/cs/oto/otoModify'),

                            }
                        ]
                    },
                ]
            },
        ],
    },


    // 유저 메뉴 시작
    {
        path: "/user",
        name: "user",
        component: () => import('@/page/user/User'),
        meta: {layout: defaultLayout},
        children: [
            {
                path: "/user",
                name: "Main",
                component: () => import('@/page/user/Main'),
                beforeEnter: checkAccess(false),
            },

            {
                path: "guide",
                name: "guide",
                component: () => import('@/page/user/Guide'),
                children: [
                    {
                        path: "service",
                        name: "service",
                        component: () => import("@/page/user/about/service"),
                        children: [
                            {
                                path: "osa",
                                name: "osa",
                                component: () => import("@/page/user/about/osa"),
                            },
                            {
                                path: "free_p",
                                name: "free_p",
                                component: () => import("@/page/user/about/free_p"),
                            },
                            {
                                path: "sfp",
                                name: "sfp",
                                component: () => import("@/page/user/about/sfp"),
                            },
                            {
                                path: "cocobox",
                                name: "cocobox",
                                component: () => import("@/page/user/about/cocobox"),
                            },
                        ]
                    },
                    {
                        path: "packing",
                        name: "packing",
                        component: () => import("@/page/user/about/packing"),
                    },
                    {
                        path: "prohibit",
                        name: "prohibit",
                        component: () => import("@/page/user/about/prohibit"),
                        beforeEnter: checkAccess(false),
                    },
                ]
            },
            // 신청하기
            {
                path: "asked",
                name: "asked",
                component: () => import('@/page/user/asked'),
                children: [
                    {
                        path: "ask_main",
                        name: "ask_main",
                        component: () => import("@/page/user/asked/ask_main"),
                    },
                    {
                        path: "shipping",
                        name: "shipping",
                        component: () => import("@/page/user/asked/shipping"),
                        beforeEnter: checkAccess(false),
                    },
                    {
                        path: "pickup",
                        name: "pickup",
                        component: () => import("@/page/user/asked/pickup"),
                        beforeEnter: checkAccess(false),
                        children: [
                            {
                                path: "addPickupInfo",
                                name: "addPickupInfo",
                                component: () => import("@/page/user/asked/addPickupInfo"),

                            },
                            {
                                path: "recipeInfo",
                                name: "recipeInfo",
                                component: () => import("@/page/user/asked/recipeInfo"),

                            },
                            {
                                path: "pickupComplete",
                                name: "pickupComplete",
                                component: () => import("@/page/user/asked/pickupComplete"),
                            },

                            {
                                path: "direct",
                                name: "direct",
                                component: () => import("@/page/user/asked/direct"),
                            },

                        ]
                    },
                    {
                        path: "purchase",
                        name: "purchase",
                        component: () => import("@/page/user/asked/purchase"),
                        beforeEnter() {
                            window.location.href = "http://boddariex.com/application";
                        },
                    },
                    {
                        path: "askguide",
                        name: "askguide",
                        component: () => import("@/page/user/asked/askGuide"),
                    },
                ],
            },
            // 배송현황
            {
                path: "ship",
                name: "ship",
                component: () => import('@/page/user/ship'),
                children: [
                    {
                        path: "stock",
                        name: "stock",
                        component: () => import("@/page/user/ship/stock"),
                        beforeEnter: checkAccess(false),
                        children: [
                            {
                                path: "stockList/:page",
                                name: "stocklist",
                                component: () => import("@/page/user/ship/stock/stockList"),
                            },
                            {
                                path: "submitpop",
                                name: "submitpop",
                                component: () => import("@/page/user/ship/stock/popup/submitPop"),
                                meta: {layout: blankLayout},
                            },
                            {
                                path: "pickuppop",
                                name: "pickuppop",
                                component: () => import("@/page/user/ship/stock/popup/pickupPop"),
                                meta: {layout: blankLayout},
                            },
                            {
                                path: "normalpop",
                                name: "normalpop",
                                component: () => import("@/page/user/ship/stock/popup/normalPop"),
                                //meta: { layout: blankLayout },
                            },
                            {
                                path: "packingComplete",
                                name: "packingComplete",
                                component: () => import("@/page/user/ship/stock/packingComplete.vue"),
                            },


                            {
                                path: "releasecomplete",
                                name: "releasecomplete",
                                component: () => import("@/page/user/ship/stock/releaseComplete"),
                            },
                            {
                                path: "returnList/:page",
                                name: "returnList",
                                component: () => import("@/page/user/ship/stock/returnList"),
                            },
                            {
                                path: "pickuppop_rt",
                                name: "pickuppop_rt",
                                component: () => import("@/page/user/ship/stock/popup/pickupPop_rt"),
                            },
                            {
                                path: "normalpop_rt",
                                name: "normalpop_rt",
                                component: () => import("@/page/user/ship/stock/popup/normalPop_rt"),
                            },
                            {
                                path: "normalpop_yes",
                                name: "normalpop_yes",
                                component: () => import("@/page/user/ship/stock/popup/normalPop_yes"),
                            },
                            {
                                path: "normalpop_no",
                                name: "normalpop_no",
                                component: () => import("@/page/user/ship/stock/popup/normalPop_no"),
                            },
                            {
                                path: "returmdetail",
                                name: "returmdetail",
                                component: () => import("@/page/user/ship/stock/popup/returmDetail"),
                            },
                            {
                                path: "bae_yes",
                                name: "bae_yes",
                                component: () => import("@/page/user/ship/stock/bae_yes"),
                            },
                            {
                                path: "shop_return",
                                name: "shop_return",
                                component: () => import("@/page/user/ship/stock/shop_return"),
                            },
                            {
                                path: "returnComplete",
                                name: "returnComplete",
                                component: () => import("@/page/user/ship/stock/returnComplete"),
                            },
                        ],
                    },

                    {
                        path: "personinput",
                        name: "personinput",
                        component: () => import("@/page/user/ship/stock/personInput"),
                    },
                    {
                        path: "addinput",
                        name: "addinput",
                        component: () => import("@/page/user/ship/stock/addInput"),
                    },
                    {
                        path: "return_address",
                        name: "return_address",
                        component: () => import("@/page/user/ship/stock/return_address"),
                    },

                    {
                        path: "return_condition",
                        name: "return",
                        component: () => import("@/page/user/ship/return_condition"),
                    },
                    {
                        path: "release",
                        name: "release",
                        component: () => import("@/page/user/ship/Release"),
                        beforeEnter: checkAccess(false),
                        children: [
                            {
                                path: "releaseList/:page",
                                name: "releaseList",
                                // component: () => import("@/page/company/stocked/release/CompanyReleaseList"),
                                component: () => import("@/page/user/ship/release/ReleaseList"),
                            },
                            {
                                path: "releaseReturnList/:page",
                                name: "releaseReturnList",
                                // component: () => import("@/page/company/stocked/release/CompanyReleaseReturnList.vue"),
                                component: () => import("@/page/user/ship/release/ReleaseReturnList"),
                            },
                        ]
                    },
                    {
                        path: "pay_list/:page",
                        name: "payment",
                        component: () => import("@/page/user/ship/PayList"),
                        beforeEnter: checkAccess(false),
                    },
                ],
            },
            // 마이페이지
            {
                path: "mypage",
                name: "mypage",
                component: () => import('@/page/user/mypage'),
                beforeEnter: checkAccess(false),
                children: [
                    {
                        path: "member",
                        name: "member",
                        component: () => import("@/page/user/mypage/member"),
                        alias: "/company/mypage/member"
                    },
                    {
                        path: "address",
                        name: "address",
                        component: () => import("@/page/user/mypage/address"),
                        children: [
                            {
                                path: "addrList_in",
                                name: "address_in",
                                component: () => import("@/page/user/mypage/addrList_in"),
                            },
                            {
                                path: "addrList_fo",
                                name: "address_fo",
                                component: () => import("@/page/user/mypage/addrList_fo"),
                            },
                        ]
                    },
                    {
                        path: "wallet",
                        name: "wallet",
                        component: () => import("@/page/user/mypage/wallet"),
                        children: [
                            {
                                path: "point/:page",
                                name: "point",
                                component: () => import("@/page/user/mypage/point"),
                            },
                            {
                                path: "coupon/:page",
                                name: "coupon",
                                component: () => import("@/page/user/mypage/coupon"),
                            },
                            {
                                path: "deposit/:page",
                                name: "deposit",
                                component: () => import("@/page/user/mypage/deposit"),
                            },
                            {
                                path: "deposit_charge",
                                name: "deposit_charge",
                                component: () => import("@/page/user/mypage/deposit_charge"),
                            },
                        ]
                    },
                ],
            },
            // 고객센터
            {
                path: "cs",
                name: "cs",
                component: () => import('@/page/user/cs'),
                children: [
                    {
                        path: "notice",
                        name: "notice",
                        component: () => import("@/page/user/cs/notice"),
                        children: [
                            {
                                path: ":currentPage",
                                name: "nlist",
                                component: () => import("@/page/user/cs/notice/nlist"),
                            },
                            {
                                path: ":boardId/:isNotice",
                                name: "nview",
                                component: () => import("@/page/user/cs/notice/view"),
                            },
                            {
                                path: "nwrite/:boardId?/:isNotice?",  // boardId가 있을 때 수정, 없을 때 작성
                                name: "nwrite",
                                component: () => import("@/page/user/cs/notice/write"),
                            }
                        ]
                    },
                    {
                        path: "event",
                        name: "event",
                        component: () => import("@/page/user/cs/event"),
                        children: [
                            {
                                path: ":currentPage",
                                name: "elist",
                                component: () => import("@/page/user/cs/event/list"),
                            },
                            {
                                path: ":boardId",
                                name: "eview",
                                component: () => import("@/page/user/cs/event/view"),
                            },
                            {
                                path: "ewrite",
                                name: "ewrite",
                                component: () => import("@/page/user/cs/event/write"),
                            },
                        ]
                    },
                    {
                        path: "faq",
                        name: "faq",
                        component: () => import("@/page/user/cs/faq"),
                    },
                    {
                        path: "through",
                        name: "through",
                        component: () => import("@/page/user/cs/through"),
                    },
                    {
                        path: "unconfirmed",
                        name: "unconfirmed",
                        component: () => import("@/page/user/cs/Unconfirmed"),
                        beforeEnter: checkAccess(false),
                    },
                    {
                        path: "fba",
                        name: "fba",
                        component: () => import("@/page/user/cs/fba"),
                    },
                    {
                        path: "oto",
                        name: "oto",
                        component: () => import("@/page/user/cs/oto"),
                        beforeEnter: checkAccess(false),
                        children: [
                            {
                                path: ":currentPage",
                                name: "otoList",
                                component: () => import("@/page/user/cs/oto/otoList.vue"),

                            },
                            {
                                path: "otoWrite",
                                name: "otoWrite",
                                component: () => import("@/page/user/cs/oto/otoWrite"),

                            },
                            {
                                path: 'otoDetail/:boardId',
                                name: 'otoDetail',
                                component: () => import('@/page/user/cs/oto/otoDetail'),

                            },
                            {
                                path: 'otoModify/:boardId',
                                name: 'otoModify',
                                component: () => import('@/page/user/cs/oto/otoModify.vue'),

                            }
                        ]
                    },
                ],
            },
        ]
    },
    // 기업 메뉴 종료
    
];



// 라우터 생성
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const userInfo = $session.load('userInfo');

    // 기업 사용자가 아닌 경우 (비회원 및 일반 사용자만 허용)
    if (to.path === '/' && userInfo && userInfo.empNo && userInfo.empNo.startsWith('K')) {
        next({ path: '/company' }); // 기업 사용자는 로그인 페이지로 리디렉션
    } else {
        next(); // 그 외의 경로는 정상적으로 진행
    }
});   // 기업로그인후 / 나 포트번호까지 입력하고 접속하고자 할때 막기

// 라우터 추출 (main.js에서 import)
export {router}