<template>
    <!-- > 페이지 확인용 -->
    <div class="tnb wrap">
        <div class="container">
            <div class="pc_view">
                <span class="txt"><img src="@/assets/img/user/tnb_txt.png" alt="보따리 익스프레스"></span>
            </div>
            <img src="@/assets/img/user/m_tnb_txt.png" alt="보따리 익스프레스" class="m_view">
        </div>
    </div>
    <header class="header wrap">
        <div class="container">
            <h1 class="m_logo"><router-link to="/user"><img src="@/assets/img/common/c_logo.png" alt="보따리 익스프레스"></router-link></h1>

                <nav class="gnb">
                    <Gnb />
                </nav>


            <div class="right">
                
                <div class="memBox" v-if="userInfo && userInfo.userNm">
                    <div class="company">
                        <!-- <button @click="goBoard">기업고객 전환 신청</button> -->
                        <i class="fas fa-building"></i>
                        <strong>{{ userInfo.userNm }}</strong>님
                    </div>
                    <button class="logout mbBtn" @click="logout">로그아웃</button>
                </div>
                <div class="noMem" v-else>
                    <a href="/login" class="login mbBtn">로그인</a>
                    <a href="/join/agree" class="join mbBtn">회원가입</a>
                </div>
                <div class="toggle"><a href=""><img src="@/assets/img/common/toggle.png" alt="토글"></a></div>
                <div class="ins">
                    <a href="https://www.instagram.com/boddari__ex"><img src="@/assets/img/common/ins_icon.png"></a>
                </div>
            </div>
            
        </div>
        <All />
    </header>
</template>

<script>
import Gnb from './Gnb.vue';
import All from './AllGnb.vue';

export default {
    name: 'main-header',
    components: {
        'Gnb': Gnb,
        'All': All,
    },
    data() {
        return {
            userInfo: null,
        };
    },
    methods: {
      goBoard() {
        this.$router.push({
          name: 'one',
          params: { currentPage: 1 }
        });
      },
        logout() {
          this.$utils.logout();
        },
    },
    mounted() {
        this.userInfo = this.$session.load('userInfo');
    }
}

</script>