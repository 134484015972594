<template>
    <component :is="$route.meta.layout || 'div'">
        <div :style="{pointerEvents: isLoading ? 'none' : 'auto'}">
            <router-view></router-view>
        </div>
    </component>
    <PSpinner @initLoad="initLoad" @endLoad="endLoad"/>
</template>

<script>
// import Login from './components/index.vue';
// import Wrap from './components/Wrap.vue';
// import Test from './components/Test.vue';

import PSpinner from "@/components/PSpinner.vue";

export default {
    name: 'App',
    components: {
        PSpinner
        //'Login':Login,
        // 'Wrap':Wrap,
        //'Test':Test,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        initLoad() {
            this.isLoading = true;
        },
        endLoad() {
            this.isLoading = false;
        }
    }
}
</script>

<style scoped src="@/assets/css/common.css">
@import 'floating-vue/dist/style.css';
</style>


