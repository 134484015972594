import {createI18n} from 'vue-i18n'

const messages = {
    "ko": {
        "예치금이 충분치 않습니다.": "예치금이 충분치 않습니다.",
        ERROR_02: "적립금이 충분치 않습니다."
    },
    "en": {
        "예치금이 충분치 않습니다.": "don't have enough deposit point.",
        ERROR_02: "don't have enough reward point."
    }
}

export const i18n = createI18n({
    locale: 'ko', // 기본 언어
    fallbackLocale: 'en', // 기본 언어 표시에 문제가 있을 경우 대체할 언어
    messages,
})