/*
Usage
    this.$cipher - 로그인시 사용될 RSA 암호화 로직

ex)
    this.$cipher.encrypt('서버에서 넘어온 module Key', '서버에서 넘어온 exponent Key', '암호화할 값')
*/

import RSAKey from './cipher/rsa'

const cipher = {
    encrypt: (module, exponent, data) => {
        // eslint-disable-next-line no-undef
        let rsa = new RSAKey();
        rsa.setPublic(module, exponent);
        return rsa.encrypt(data)
    }
}

export { cipher }


