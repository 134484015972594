<template>
    <div>
    <Header />
    <slot />
    <Footer />
    </div>
</template>

<script>
import Header from '../components/user/Header.vue';
import Footer from '../components/user/Footer.vue';

export default {
    components: {
        'Header':Header,
        //'Body':Body,
        //'Main':Main,
        'Footer':Footer
    }
}
</script>