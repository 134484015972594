<template>

    <footer class="footer wrap">
        <div class="container">
            <h1 class="m_logo"><a href="/"><img src="@/assets/img/common/w_logo.png" alt="보따리 익스프레스"></a></h1>
            <div class="mid_line">
                <ul class="box">
                    <li class="mgb">해외특송&우체국해외배송 보따리익스프레스</li>
                    <li>고객센터 : 1800-7024</li>
                    <li>24시간 연중무휴 카카오톡 상담</li>
                </ul>
                <ul class="box">
                    <li>상호명 : 한국표준상사</li>
                    <li>사업자등록번호 : 491-81-01728</li>
                    <li class="mgt"><a href="">이용약관</a><a href="">개인정보처리방침</a></li>
                </ul>
                <ul class="box">
                    <li><img src="@/assets/img/common/ems_logo.png" alt="EMS"></li>
                </ul>
            </div>
            <p class="copyright">Copyright ⓒ 2022 보따리해외배송 All rights reserved.</p>
        </div>
    </footer>

</template>

<script>
export default {
    name: 'main-footer',
}
</script>

