/*
Usage
    this.$globals - 글러벌 상수 및 정규식

*/

let constants = {
    // title: 'ToHome',
    serverPort: 18087,
    databaseDiv: 'MYSQL',
    isActivatedTabs: true, //  탭 사용여부
    isKeepAlive: true, // 탭이동시 비활성화 탭의 상태를 보전 여부
    defaultOwnerCd: 'PFS',
    workpermitColors: [
        '#788080' // WRITE
        ,'#fa8942' // REQUEST
        ,'#4991de' // WORKING
        ,'#a742fa' // PAUSE
        ,'#e86363' // CANCEL
        ,'#5874e8' // COMPLETE
    ],

    /* 로그인 API key */
    /* 네이버 */
    naver:{
        clientId: 'PsVK0CWGKXXLsaSSbYY8',
        loginCallbackUrl: `${window.location.origin}/login/naver`,
        userInfoLinkCallbackUrl: `${window.location.origin}/mypage/member/link/naver`
    },

    kakao:{
        key: '38b87d5caaa32339d53717dd1963bf28',
        loginCallbackUrl: `${window.location.origin}/login/kakao`,
        userInfoLinkCallbackUrl: `${window.location.origin}/mypage/member/link/kakao`,
    },

    google: {
        key: '599909282289-kaqep9n22fun21hi95toa1cemj5lnum5.apps.googleusercontent.com',
        loginCallbackUrl: `${window.location.origin}/login/google`,
        userInfoLinkCallbackUrl: `${window.location.origin}/mypage/member/link/google`,
    },

    /* 정규식 관련 */
    regularId: {
        exp: "/^[a-z0-9]{6,20}$/",
        message: '계정은 6~20자 이내의 영문소문자 또는 숫자만 사용 가능합니다.',
    },
    regularPwd: {
        exp: "/^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!?@#$%^&*():;+-=~{}<>\u005F\u005B\u005D\u007C\u005C\u0022\u0027\u002C\u002E\u002F\u0060])[A-Za-z0-9!?@#$%^&*():;+-=~{}<>\u005F\u005B\u005D\u007C\u005C\u0022\u0027\u002C\u002E\u002F\u0060]{8,}$/",
        message: '비밀번호는 영문자/숫자/특수문자 조합 8자 이상으로 사용 가능합니다.',
    },
    regularEmail: {
        // exp: "/^[0-9a-zA-Z]+([-_.]*[0-9a-zA-Z]+)*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i",
        exp: "/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$/",
        message: '올바른 이메일 형식이 아닙니다.',
    },
    regularPhoneNo: {
        cc:  /^(0[2-6][0-5]?|01[01346-9])-?([1-9]{1}[0-9]{2,3})-?([0-9]{4})|((080-[0-9]{3,4}|15(44|66|77|88))-[0-9]{4})$/,
        exp: "/^" +
            "(0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-([0-9]{3,4})-([0-9]{4})" + // 유선
            "|(030|(050[2-8]{0,1})|060|070|080)-?([0-9]{3,4})-?[0-9]{4}" + // 가상전화
            "|((0(2|3[1-3]|4[1-4]|5[1-5]|6[1-4]))-?)?(15|16|17|18|19)[0-9]{2}-?[0-9]{4}" + // 대표전화
            "|01[01346-9]-?([0-9]{3,4})-?([0-9]{4})" + // 모바일
            "$/",
        message: '올바른 전화번호 형식이 아닙니다.',
    },
    regularMobile: {
        exp: "/^01[01346-9]-?([0-9]{3,4})-?([0-9]{4})$/",
        message: '올바른 휴대전화번호 형식이 아닙니다.',
    },
    regularBusinessNo: {
        exp: "/^[0-9]{3}-[0-9]{2}-[0-9]{5}$/",
        message: '올바른 사업자번호 형식이 아닙니다.',
    },
    regularUserNm:{
        exp:"/^[가-힣a-zA-Z]{2,20}$/",
        message: '최소 2자이상의 이름으로 입력해주세요.'
    },
    regularMoney: {
      exp: "/\\B(?=(\\d{3})+(?!\\d))/g",
    },
    buttonTitles: {
        add: "추가",
        copy: "복사",
        remove: "삭제",
        reset: "복원",
        save: "저장",
        download: "엑셀다운"
    },
    SEARCH_PERIOD: {
        ALL: {value: "all", label: "전체"},
        ONE_WEEK: {value: "oneWeek", label: "1주일"},
        ONE_MONTH: {value: "oneMonth", label: "1개월"},
        THREE_MONTH: {value: "threeMonth", label: "3개월"},
        SIX_MONTH: {value: "sixMonth", label: "6개월"},
    },
    MAIL_SUFFIX: {
      DIRECT: {
        value: '',
        show: "직접입력",
      },
      NAVER: {
        value: "naver.com",
        show: "naver.com",
      },
      KAKAO: {
        value: "kakao.com",
        show: "kakao.com",
      },
      GMAIL: {
        value: "gmail.com",
        show: "gmail.com",
      },
      HANMAIL: {
        value: "hanmail.net",
        show: "hanmail.net",
      },
      YAHOO: {
        value: "yahoo.com",
        show: "yahoo.com",
      },
    }
}

export { constants }
